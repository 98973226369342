.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0;
  padding: 0;
  background: var(--lightYellow);
  border-radius: 0.8rem 0.8rem 0 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  border-radius: 0.8rem 0.8rem 0 0;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: var(--lightYellow);
}

.react-tabs__tab--selected {
  background: var(--medYellow);
  border-color: #aaa;
  color: black;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
